import React from "react"
import { Link } from "gatsby"
import { rhythm, scale } from "../utils/typography"

class Layout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <div>
        <nav className="py-4 text-black border-b border-gray-300 mb-18">
          <div className="flex items-center justify-between px-3 md:px-5">
            <Link to="/">
              <h3 className="text-xl font-black md:text-3xl">
                <span className="text-4xl font-semibold tracking-tight text-gray-800">
                  Backtick Blog
                </span>
              </h3>
            </Link>

            <a
              href="https://www.backtick.consulting"
              className="px-2 py-3 text-sm font-bold text-white bg-indigo-700 rounded md:px-4 md:text-lg"
            >
              Get in Touch!
            </a>
          </div>
        </nav>
        <div className="max-w-3xl px-5 py-6 pb-10 mx-auto md:px-0">
          <main className="divide-y divide-gray-300">{children}</main>
        </div>
        <footer className="px-5 py-6 border-t border-gray-300 md:mx-0">
          <div className="max-w-3xl mx-auto text-lg font-semibold">
            © {new Date().getFullYear()}
            <a
              className="ml-2 text-blue-700"
              href="https://www.backtick.consulting"
            >
              Backtick Consulting
            </a>
          </div>
        </footer>
      </div>
    )
  }
}

export default Layout
